
import Vue from "vue";
import { AssociateRoles, ICompany, ICompanyAccount } from "@/api";
import { getDate } from "@/utils";
import ProjectsOverview from "@/components/dashboard/DashboardProjectsOverview.vue";
import DashboardGroups from "@/components/dashboard/DashboardGroups.vue";
import DashboardEquipments from "@/components/dashboard/DashboardEquipments.vue";
import DashboardLatestProjects from "@/components/dashboard/DashboardLatestProjects.vue";
import DashboardProjectActivityLog from "@/components/dashboard/DashboardProjectActivityLog.vue";
import DashboardSupport from "@/components/common/dashboard/DashboardSupport.vue";
import UploadProjectDialog from "@/components/projects/UploadProjectDialog.vue";
import { Actions } from "@/store/models";

export default Vue.extend({
  name: "Dashboard",
  components: {
    ProjectsOverview,
    DashboardGroups,
    DashboardEquipments,
    DashboardLatestProjects,
    DashboardProjectActivityLog,
    DashboardSupport,
    UploadProjectDialog,
  },
  data() {
    return {
      AssociateRoles: AssociateRoles,
      uploadProjectDialog: false,
    };
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
    account: function (): ICompanyAccount {
      return this.$store.state.company.account;
    },
    details: function (): any {
      const { cao, license_info } = this.account || {};
      return [
        { label: "Account Number", text: license_info?.account_number },
        {
          label: "Renewal Date",
          text: getDate(cao?.company?.renewal_date || ""),
        },
        {
          label: "Account Owner",
          text: `${cao?.first_name} ${cao?.last_name}`,
        },
        { label: "Company", text: cao?.company?.name },
      ];
    },
    roles: function () {
      return this.$store.getters.associateRoles;
    },
  },
  methods: {
    getActivityLog() {
      (this.$refs.activityLog as any).getItems();
    },
    hasPermission(permission: AssociateRoles) {
      return this.roles.has(permission);
    },
    async projectsUpdated() {
      this.getActivityLog();
      await this.$store.dispatch(Actions.GetCompanyProjects);
      (this.$refs.projectsOverview as any).getTotalProjects();
      (this.$refs.projectsOverview as any).getTotalProjectsBy();
      (this.$refs.dashboardEquipments as any).getEquipments();
      (this.$refs.dashboardLatestProjects as any).getItems();
    },
  },
});
